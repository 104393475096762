import Vue from 'vue'
import { EVENT_BUS_COMPONENT_FIELD, ListenerStrategy } from '@/services/EventBus/event_bus_interfaces'
import EventBus from '@/services/EventBus/EventBus'

/**
 * Декоратор для добавления слушателя глобальных
 * событий (на объекте Window)
 *
 * Первым аргументом можно передать тип события (строковый ID)
 * Второй - метод инициализации. Для Vue-компонентов автоматом подхватывается
 * "mounted()". Если используете в стороннем классе, передайте его сюда.
 * Если не передать метод, то событие привяжется только один раз, при первом
 * создании объекта.
 *
 * Второй аргумент - метод уничтожения. Для Vue-компонентов автоматом
 * подхватывается beforeDestroy. В нём вызывается removeEventListener.
 * Если используете в стороннем классе, передайте его сюда.
 * Если не передать метод, то декоратор добавит в класс метод "__unsubscribeEvent",
 * который нужно будет вызвать вручную при уничтожении класса.
 * ЭТО ВАЖНО!!! Не отвязанные слушатели порождают утечки памяти!
 *
 * @param eventType - название события
 * @param startupMethod - метод инициализации
 * @param destroyMethod - метод уничтожения
 * @constructor
 */
export function EventListener(
  eventType: string | string[] = 'happyjob_global_event',
  startupMethod?: any,
  destroyMethod?: any
) {
  return function (target: any, propertyKey: string) {
    if (typeof target[propertyKey] !== 'function') {
      console.error('"EventListener" decorator works only with functions!')
      return
    }
    const isVue = target instanceof Vue

    if (isVue) {
      const oldMounted = target.mounted
      target.mounted = function wrappedMounted(...args: any) {
        oldMounted && oldMounted.apply(this, args)
        const componentContext = this
        if (!componentContext.__listeners) {
          Object.defineProperties(componentContext, {
            __listeners: {
              value: {},
              configurable: true,
              writable: true
            },
            __addListener: {
              value: function (eventType: string, listener: any) {
                const r = Math.random().toString(36).substring(7)
                const eventName = `${eventType}%%%${r}`
                this.__listeners[eventName] = listener.bind(componentContext)
                window.addEventListener(eventType, this.__listeners[eventName])
              }
            },
            __removeListeners: {
              value: function () {
                for (const listener in this.__listeners) {
                  const eventOriginalType = listener.split('%%%')[0]
                  window.removeEventListener(eventOriginalType, this.__listeners[listener])
                }
              }
            }
          })
        }
        const eventsArray = Array.isArray(eventType) ? eventType : [eventType]
        for (const event of eventsArray) {
          componentContext.__addListener(event, target[propertyKey])
        }
      }

      const oldBeforeDestroy = target.beforeDestroy
      target.beforeDestroy = function wrappedBeforeDestroy(...args: any) {
        oldBeforeDestroy && oldBeforeDestroy.apply(this, args)
        this.__removeListeners && this.__removeListeners()
      }
    } else {
      console.error('Currently not working with non-vue classes')
    }
  }
}

/**
 * Декоратор, асинхронно ждущий, пока переменная не будет логически false
 *
 * @param property
 * @param timeout
 * @constructor
 */
export function WaitFor(property: string, timeout = 500) {
  return function (target: any, propertyKey: string, descriptor: any) {
    if (typeof target[propertyKey] !== 'function') {
      console.error('"WaitFor" decorator works only with functions!')
      return
    }

    const originalMethod = target[propertyKey]
    const newDescriptor = {
      ...descriptor,
      value: async function (...args: any) {
        do {
          await new Promise((resolveWaiter) => setTimeout(resolveWaiter, timeout))
        } while (!this[property])
        return await originalMethod.call(this, ...args)
      }
    }

    Object.defineProperty(target, propertyKey, newDescriptor)
    return newDescriptor
  }
}

/**
 * Подписка метода на событие из EventBus
 *
 * @param eventName
 * @param strategy - стратегия (once - среагировать один раз, every - реагировать на каждое)
 * @param immediate - сразу ли запускать хендлер, если событие уже когда-то отправлялось
 * @constructor
 */
export function SubscribeBus(eventName: string | string[], strategy: ListenerStrategy, immediate = true) {
  return function (target: any, propertyKey: string, descriptor: any) {
    const typeofTarget = typeof target[propertyKey]
    if (typeofTarget !== 'function') {
      console.error(`"SubscribeBus" decorator works only with functions, "${typeofTarget}" given.`)
      return
    }
    const originalMounted = target.mounted
    const events = ([] as string[]).concat(eventName)

    target.mounted = function () {
      for (const eventName of events) {
        const unsubscribe = EventBus[strategy](eventName, this[propertyKey], immediate)
        unsubscribe && this._data[EVENT_BUS_COMPONENT_FIELD].push(unsubscribe)
      }
      originalMounted && originalMounted.call(this)
    }

    const originalBeforeDestroy = target.beforeDestroy

    target.beforeDestroy = function () {
      for (const unsubscribe of this._data[EVENT_BUS_COMPONENT_FIELD]) {
        unsubscribe()
      }
      originalBeforeDestroy && originalBeforeDestroy.call(this)
    }
  }
}
