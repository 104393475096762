export const BUS_CREATED = 'event-bus-created'
export const CLIENTS_LOADED = 'clients-loaded'
export const STUDIES_LOADED = 'studies-loaded'
export const CYCLES_LOADED = 'cycles-loaded'
export const SURVEYS_LOADED = 'surveys-loaded'
export const STRUCTURE_LOADED = 'structure-loaded'
export const CYCLE_SELECTED = 'cycle-selected'
export const SURVEYS_SELECTED = 'surveys-selected'
export const CLIENTS_HISTORY_UPDATED = 'clients-history-updated'
export const LOCALES_LOADED = 'locales-loaded'
export const API_READY = 'api-ready'
