import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { Client, Cycle, ExcelExportEntity, ExcelImportEntity, Study, Survey, TokenValue } from '@/types_and_interfaces'
import EventBus from '@/services/EventBus/EventBus'
import { CYCLE_SELECTED, SURVEYS_SELECTED } from '@/services/EventBus/AppEvents'
import { LanguageSet } from '@/data/LanguagesSet'
import Vue from 'vue'
import { stringifyClone } from '@/services/Utils/stringifyClone'
import { FEEDBACKX } from '@/data/ViewScopes'
import { RolesLocalizedAttributes } from '@/views/Pages/UsersManagement/feature/stores/RolesStore.types'
import { CycleAttributes } from '@/views/Pages/CycleEdit/attributes/types'
import { cycleAttributesConst } from '@/views/Pages/CycleEdit/attributes/consts'
const createData = () => {
  const accountToken: TokenValue = null
  const studyToken: TokenValue = null
  const studiesList: Study[] = []
  const clientsList: Client[] = []
  const cyclesList: Cycle[] = []
  const cycleAttributes: CycleAttributes = stringifyClone(cycleAttributesConst)
  const surveysList: Survey[] = []
  const selectedClientId: any = null
  const selectedCycleId: any = null
  const selectedSurveyId: any = null
  const structuresList: any[] = []
  const selectedStudy = ''
  const studyLocales: string[] = []
  const currentLocale = 'ru'
  const metrics: any[] = []
  const themeTrees: any[] = []
  const metricTrees: any[] = []
  const settingMode = false
  const localizedAttributes: RolesLocalizedAttributes = []
  const rootFilters: any[] = []
  const excelImportEntities: ExcelImportEntity[] = []
  const excelExportEntities: ExcelExportEntity[] = []
  const exportListVisible = false
  const importListVisible = false
  const isMinimizeExportNotificationList = false
  const isMinimizeImportNotificationList = false

  return {
    accountToken,
    studyToken,
    studiesList,
    clientsList,
    cyclesList,
    cycleAttributes,
    surveysList,
    selectedClientId,
    selectedCycleId,
    selectedSurveyId,
    structuresList,
    selectedStudy,
    studyLocales,
    currentLocale,
    metrics,
    themeTrees,
    metricTrees,
    settingMode,
    localizedAttributes,
    rootFilters,
    excelImportEntities,
    excelExportEntities,
    exportListVisible,
    importListVisible,
    isMinimizeExportNotificationList,
    isMinimizeImportNotificationList
  }
}

@Module({
  namespaced: true
})
export default class BaseStore extends VuexModule {
  private data = createData()

  @Mutation
  setAccountToken(value: TokenValue) {
    ;(this.data.accountToken as TokenValue) = value
  }

  @Mutation
  setStudyToken(value: TokenValue) {
    ;(this.data.studyToken as TokenValue) = value
  }

  @Mutation
  setStudiesList(value: Study[]) {
    this.data.studiesList = value
  }

  @Mutation
  addStudyToList(value: Study) {
    const isHaving = this.data.studiesList.find((study) => study.study!.id === value.study!.id)
    if (!isHaving) this.data.studiesList.push(value)
  }

  @Mutation
  deleteStudy(id: string) {
    const isHaving = this.data.studiesList.findIndex((study) => study.study!.id === id)
    if (isHaving !== -1) {
      this.data.studiesList.splice(isHaving, 1)
    }
  }

  @Mutation
  setClientsList(value: Client[]) {
    this.data.clientsList = value
  }

  @Mutation
  setCyclesList(value: Cycle[]) {
    this.data.cyclesList = value
  }

  @Mutation
  setCycleAttributes(value: CycleAttributes) {
    this.data.cycleAttributes = value
  }

  @Mutation
  selectCycle(value: string) {
    this.data.selectedCycleId = value
    EventBus.register(CYCLE_SELECTED)
  }

  @Mutation
  setSurveysList(value: Survey[]) {
    this.data.surveysList = value
  }

  @Mutation
  selectSurvey(value: string) {
    this.data.selectedSurveyId = value
    EventBus.register(SURVEYS_SELECTED)
  }

  @Mutation
  setStructures(payload: any[]) {
    this.data.structuresList = payload
  }

  @Mutation
  setSelectedStudy(payload: string) {
    this.data.selectedStudy = payload
  }

  @Mutation
  updateClientData(client: Client) {
    const searchClient = this.data.clientsList.find((cli) => cli.id === client.id)
    for (const key in client) {
      if (searchClient) {
        // @ts-ignore
        searchClient[key] = client[key]
      }
    }
  }

  @Mutation
  setStudyLocales(payload: string[]) {
    this.data.studyLocales = payload
    if (payload.length === 1) this.data.currentLocale = payload[0]
  }

  @Mutation
  setCurrentLocale(payload: string) {
    this.data.currentLocale = payload
  }

  @Mutation
  setSelectedClientId(payload: string) {
    this.data.selectedClientId = payload
  }
  @Mutation
  setLoadedMetric(payload: any) {
    this.data.metrics = payload
  }

  @Mutation
  setThemeTrees(payload: any) {
    this.data.themeTrees = payload
  }

  @Mutation
  setSettingMode(payload: any) {
    this.data.settingMode = payload
  }

  @Mutation
  setStudyFullStructurePermission(payload: { studyId: string; state: boolean }) {
    for (let i = 0; i < this.data.studiesList.length; i++) {
      const study = this.data.studiesList[i]
      if (study.id === payload.studyId) {
        Vue.set(this.data.studiesList[i], 'fullStructureReportsEnabled', payload.state)
        break
      }
    }
  }

  @Mutation
  setLocalizedAttributes(payload: RolesLocalizedAttributes) {
    this.data.localizedAttributes = payload
  }

  @Mutation
  setRootFilters(payload: any) {
    this.data.rootFilters = payload
  }

  @Mutation
  setExcelImportEntities(excelImportEntities: ExcelImportEntity[]): void {
    this.data.excelImportEntities = excelImportEntities
  }

  @Mutation
  setExcelExportEntities(excelExportEntities: ExcelExportEntity[]): void {
    this.data.excelExportEntities = excelExportEntities
  }

  @Mutation
  setExportListVisible(payload: boolean) {
    this.data.exportListVisible = payload
  }

  @Mutation
  setIsMinimizeExportNotificationList(payload: boolean) {
    localStorage.isMinimizeExportNotificationList = payload
    this.data.isMinimizeExportNotificationList = payload
  }

  @Mutation
  setImportListVisible(payload: boolean) {
    this.data.importListVisible = payload
  }

  @Mutation
  setIsMinimizeImportNotificationList(payload: boolean) {
    localStorage.isMinimizeImportNotificationList = payload
    this.data.isMinimizeImportNotificationList = payload
  }

  get getStudies() {
    return this.data.studiesList
  }

  get getStudyToken() {
    return this.data.studyToken
  }

  get getAccountToken() {
    return this.data.accountToken
  }

  get getCycles() {
    return this.data.cyclesList
  }

  get getCycleAttributes() {
    return this.data.cycleAttributes
  }

  get getActiveCycle() {
    if (!this.data.cyclesList.length) return null
    const cycleWithActiveAttrib = this.data.cyclesList.find((cycle) => cycle.active)
    if (cycleWithActiveAttrib) return cycleWithActiveAttrib
    else {
      const cyclesCopy: Cycle[] = stringifyClone(this.data.cyclesList)
      cyclesCopy.sort((a, b) => {
        return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      })
      return cyclesCopy[0]
    }
  }

  get getSelectedCycle() {
    if (this.data.selectedCycleId) {
      return this.data.cyclesList.find((cycle) => cycle.id === this.data.selectedCycleId) || null
    } else {
      return this.getActiveCycle
    }
  }

  get getSurveys() {
    return this.data.surveysList
  }

  get getActiveSurvey() {
    const selectedCycle = this.getSelectedCycle
    const activeSurvey = this.data.surveysList.find((survey) => survey.id === selectedCycle?.activeSurveyId)
    return activeSurvey || null
  }

  get getSelectedSurvey() {
    if (this.data.selectedSurveyId) {
      return this.data.surveysList.find((survey) => survey.id === this.data.selectedSurveyId) || null
    } else if (this.getActiveSurvey) {
      return this.getActiveSurvey
    } else {
      return this.data.surveysList[0] || null
    }
  }

  get clients() {
    return this.data.clientsList
  }

  get getStructures() {
    return this.data.structuresList
  }

  get getActiveStructure() {
    const activeCycle = this.getActiveCycle
    if (!activeCycle) return null
    const activeStructureId = activeCycle.activeCompanyStructureId
    const activeStructure = this.data.structuresList.find(
      (structure) => structure.companyStructureId === activeStructureId
    )
    return activeStructure || null
  }

  get getSelectedStudy() {
    if (!this.data.selectedStudy) return null
    const study = this.data.studiesList.find((study) => study.id === this.data.selectedStudy)
    return study || null
  }

  get getStudyLocales() {
    return this.data.studyLocales
  }

  get getCurrentLocale() {
    return this.data.currentLocale
  }

  get isFeedbackx(): boolean {
    const currentStudyType = this.getSelectedStudy?.type || ''
    return currentStudyType === FEEDBACKX
  }

  get selectedLanguages() {
    return LanguageSet.filter((lang) => this.data.studyLocales.includes(lang.id))
  }

  get selectedClientId() {
    return this.data.selectedClientId
  }
  get getLoadedMetrics() {
    return this.data.metrics
  }
  get getThemeTrees() {
    return this.data.themeTrees
  }
  get getSettingsMode() {
    return this.data.settingMode
  }
  get getLocalizedAttributes() {
    return this.data.localizedAttributes
  }
  get getRootFilters() {
    return this.data.rootFilters
  }
  get getExcelImportEntities(): ExcelImportEntity[] {
    return this.data.excelImportEntities
  }

  get getExcelExportEntities(): ExcelExportEntity[] {
    return this.data.excelExportEntities
  }

  get getIsMinimizeExportNotificationList() {
    return this.data.isMinimizeExportNotificationList
  }

  get getExportListVisible() {
    return this.data.exportListVisible
  }

  get getIsMinimizeImportNotificationList() {
    return this.data.isMinimizeImportNotificationList
  }

  get getImportListVisible() {
    return this.data.importListVisible
  }
}
