import { CycleAttributes, KeyAndChecker } from '@/views/Pages/CycleEdit/attributes/types'
import { stringifyClone } from '@/services/Utils/stringifyClone'

/**
 * Для валидации уже существующих атрибутов. При изменении их схемы нам нужно будет убедиться,
 * что все необходимые ключи будут существовать. Ниже - карта в формате:
 * текст ключа - функция проверки - значение по умолчанию
 */
const keysAndCheckersMap: KeyAndChecker[] = [
  ['hiddenMenuButtons', (v: unknown) => Array.isArray(v), []], // Массив кнопок меню, которые нужно "спрятать"
  ['hideLanguageSelector', (v: unknown) => typeof v === 'boolean', false], // Скрывать ли селектор языка
  ['hideGeneratorButton', (v: unknown) => typeof v === 'boolean', false], // Скрывать ли кнопку генератора презентаций
  ['excludeEmptyFilters', (v: unknown) => typeof v === 'boolean', false], // Скрыть пустые фильтры по умолчанию
  ['startPage', (v: unknown) => typeof v === 'string', ''], // Стартовая страница панели
  ['customCss', (v: unknown) => typeof v === 'string', ''], // Кастомный CSS
  ['customJs', (v: unknown) => typeof v === 'string', ''], // Кастомный JS
  ['customJsonData', (v: unknown) => typeof v === 'string', '{}'], // Кастомный JSON
  ['showedFiltersInComments', (v: unknown) => Array.isArray(v), []], // Открытые фильтры в комментариях
  ['engagementMetricDefault', (v: unknown) => typeof v === 'string', ''], // Метрика в отчете "Вовлеченность - Бенчмарки"
  ['loyaltyMetricDefault', (v: unknown) => typeof v === 'string', ''], // Метрика в отчете "Лояльность - Бенчмарки"
  ['satisfactionMetricDefault', (v: unknown) => typeof v === 'string', ''], // Метрика в отчете "Удовлетворенность - Бенчмарки"
  ['showActionPlanSurvey', (v: unknown) => typeof v === 'boolean', false], // Показать план работ в Опроскин
  ['isHiddenParticipantAmount', (v: unknown) => typeof v === 'boolean', false], // Скрыть количество сотрудников в фильтре (Статистика участия)
  [
    'groupWidgetSettings',
    (v: unknown) => typeof v === 'object',
    {
      min: { widgetTitle: '', widgetValue: '' },
      avg: { widgetTitle: '', widgetValue: '' },
      max: { widgetTitle: '', widgetValue: '' }
    }
  ], // Настройки для группового виджета для отчета EVP
  ['hideBenchmarks', (v: unknown) => typeof v === 'boolean', false], // Скрыть бенчмарки
  ['showCompanyNames', (v: unknown) => typeof v === 'boolean', false] // Показать названия компаний в бенчмарках
]

/**
 * Валидация и автоматическое исправление схемы объекта атрибутов
 * @param attributes
 */
export function correctAttributesSchema(attributes: Partial<CycleAttributes>): CycleAttributes {
  const workWithObject: Partial<CycleAttributes> =
    Object.prototype.toString.call(attributes) === '[object Object]' ? stringifyClone(attributes) : {}
  for (const keyChecker of keysAndCheckersMap) {
    const [key, checker, defaultValue] = keyChecker
    if (!Object.prototype.hasOwnProperty.call(workWithObject, key) || !checker(workWithObject[key])) {
      Object.defineProperty(workWithObject, key, {
        value: defaultValue,
        writable: true,
        enumerable: true,
        configurable: true
      })
    }
  }
  return workWithObject as CycleAttributes
}

/**
 * Создать чистый объект атрибутов на основе описанной выше схемы
 */
export function attributesFactory(): CycleAttributes {
  return correctAttributesSchema({})
}
