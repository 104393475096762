import { PluginObject } from 'vue'

import ApiService from '@/services/ApiService'

const ApiServicePlugin = async (vue: any, store: any): Promise<PluginObject<any>> => {
  const apiService = new ApiService(true, vue, store)
  // await apiService.loadSpec()
  return {
    install: function (Vue) {
      Vue.prototype.$api = apiService
    }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $api: ApiService
  }
}

export default ApiServicePlugin
