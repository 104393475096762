import Vue from 'vue'
import Vuex from 'vuex'

import BaseStore from '@/store/BaseStore/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // Сюда можно добавлять Vuex-модули
    // https://vuex.vuejs.org/ru/guide/modules.html
    BaseStore
  }
})
