import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import EventBusPlugin from '@/plugins/EventBusPlugin'
import ApiServicePlugin from '@/plugins/ApiServicePlugin'
import AuthServicePlugin from '@/plugins/AuthServicePlugin'
import AccountServicePlugin from '@/plugins/AccountServicePlugin'
import locale from '@/data/ElementRuLocale'

import 'element-ui/packages/theme-chalk/src/index.scss'
import './scss/index.scss'
import StudyType from '@/views/Components/StudyType'
import ModeType from '@/views/Components/ModeType'

Vue.use(Element, { locale })

async function createApp() {
  Vue.use(EventBusPlugin)
  Vue.use(AuthServicePlugin, { router })
  Vue.use(await ApiServicePlugin(Vue, store))
  Vue.use(AccountServicePlugin, { store })
  Vue.component('study-type', StudyType)
  Vue.component('mode-type', ModeType)
  Vue.config.productionTip = false

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
}

createApp()
