import { Notification } from 'element-ui'

export default class NotifyedError extends Error {
  constructor(error: Error | null, notificationText = 'Возникла непредвиденная ошибка') {
    if (error instanceof Error) {
      if (error.message) {
        const body = (error as any).response?.body
        if (body?.fields && Array.isArray(body.fields) && body.fields.length && body.fields[0].message) {
          body.fields.forEach((error: Error) => {
            notificationText = `${notificationText}<br><br>Текст ошибки: "${error.message}"`
          })
        } else if (body?.title) {
          notificationText = `Код ошибки ${body.status}<br><br>${body.title}`
        } else {
          notificationText = `${notificationText}<br><br>Текст ошибки: "${error.message}"`
        }
      }
    }
    Notification.error({
      message: notificationText,
      type: 'error',
      title: 'Ошибка',
      dangerouslyUseHTMLString: true,
      duration: 5000
    })
    super(error?.message || 'NotifyedError')
  }
}
