import TokenStorageService from '@/services/TokenStorageService'
import { ILoginPayload, TokenValue } from '@/types_and_interfaces'
import CookieRepository from '@/services/Repositories/CookieRepository'
import NotifyedError from '@/data/NotifyedError'

export default class AuthService {
  private readonly api: any

  private vue: any
  private router: any

  public readonly LAST_STUDY_COOKIE_NAME: string = 'a23a23e96ee0'
  public tokenService: TokenStorageService
  public currentEmail = new CookieRepository('df86380c2714')

  constructor(vue: any, router: any) {
    if (!vue) throw new Error('Cannot find Vue instance')
    this.vue = vue
    this.api = this.vue.$api || this.vue.prototype.$api
    this.router = router
    this.tokenService = new TokenStorageService(this.api)
  }

  public checkAuthByToken() {
    if (!this.tokenService.tokens.account?.value) throw new Error('noaccouttoken')
    if (!this.tokenService.tokens.study?.value) throw new Error('nostudytoken')
  }

  public async acceptStudyToken(token: TokenValue, studyId: string | null) {
    this.tokenService.tokens.study!.value = token
    if (studyId) {
      const studyCookie = new CookieRepository(this.LAST_STUDY_COOKIE_NAME)
      studyCookie.value = studyId
    }
  }

  public getPreviouslyUsedStudy() {
    try {
      const studyCookie = new CookieRepository(this.LAST_STUDY_COOKIE_NAME)
      return studyCookie.value
    } catch (e) {
      console.error(e)
      return null
    }
  }

  public async doLogin(loginPayload: ILoginPayload): Promise<boolean> {
    try {
      const loginResult = await this.vue.prototype.$api.request(
        `${process.env.VUE_APP_API}/authenticate`,
        'POST',
        {},
        JSON.stringify(loginPayload)
      )
      this.tokenService.tokens.account!.value = loginResult.body.token
      this.currentEmail.value = btoa(loginPayload.email)
      await this.vue.prototype.$api.loadSpec()
      return true
    } catch (e) {
      console.error(e)

      if ((e as any).statusCode === 401) {
        new NotifyedError(e as Error, 'Неверный логин или пароль')
      } else if (e.statusCode === 4043) {
        new NotifyedError(e as Error, 'Нет доступа к исследованию. Пожалуйста, обратитесь к вашему менеджеру')
      } else {
        new NotifyedError(e as Error)
      }
      return false
    }
  }

  public doLogout() {
    this.tokenService.deleteToken(true)
    const studyCookie = new CookieRepository(this.LAST_STUDY_COOKIE_NAME)
    studyCookie.deleteValue()
    this.vue.prototype.$account.selectedStudy.deleteValue()
    this.currentEmail.deleteValue()
    window.location.href = '/#/login'
  }
}
