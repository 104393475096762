import Vue from 'vue'

import { NavigationGuard } from 'vue-router'

const routerAuthGuard: NavigationGuard = function (to, from, next) {
  const token = Vue.prototype.$authService.tokenService.tokens.account?.value
  if (!token) {
    if (to.name !== 'Login')
      next({
        path: '/login',
        query: to.query
      })
    else next()
  } else {
    if (to.name === 'Login') next('/')
    else next()
  }
}

export default routerAuthGuard
