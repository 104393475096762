import { BUS_CREATED } from './AppEvents'
import createEventBus from 'happyinc-utils/packages/createEventBus'

export class EventBus {
  private readonly bus = createEventBus()
  private history = new Set<string>()

  constructor() {
    this.debugLog('created instance')
    this.register(BUS_CREATED)
  }

  public register(eventName: string, meta?: any) {
    this.debugLog(`registered event ${eventName}`)
    this.history.add(eventName)
    this.bus.dispatch(eventName, meta)
  }

  public every(eventName: string, callback: CallableFunction, immediate = true) {
    this.debugLog(`Subscribed listener (every) "${callback.name}" on "${eventName}"`)
    if (immediate && this.history.has(eventName)) callback()
    return this.bus.subscribe(eventName, callback)
  }

  public once(eventName: string, callback: CallableFunction, immediate = true) {
    this.debugLog(`Subscribed listener (once) "${callback.name}" on "${eventName}"`)
    if (immediate && this.history.has(eventName)) {
      callback()
      return
    }
    this.bus.once(eventName, callback)
  }

  protected debugLog(message: string) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(':: Event bus:', message)
    }
  }
}

export default new EventBus()
