import { Component, Vue, Prop } from 'vue-property-decorator'
import { HAPPY_JOB, HAPPY_SERVICE, HAPPY_SURVEY, FEEDBACKX } from '@/data/ViewScopes'

@Component
export default class StudyType extends Vue {
  @Prop({ default: false, type: Boolean }) happyService!: boolean
  @Prop({ default: false, type: Boolean }) happySurvey!: boolean
  @Prop({ default: false, type: Boolean }) happyJob!: boolean
  @Prop({ default: false, type: Boolean }) feedbackx!: boolean
  @Prop({ default: false, type: Boolean }) none!: boolean
  @Prop({ default: 'span' }) tag!: string
  @Prop({ default: '' }) className!: string

  render(createElement: any) {
    const studyType = this.$account.currentStudyType

    if (!studyType && !this.none) return
    if (studyType === HAPPY_SERVICE && !this.happyService) return
    if (studyType === HAPPY_SURVEY && !this.happySurvey) return
    if (studyType === HAPPY_JOB && !this.happyJob) return
    if (studyType === FEEDBACKX && !this.feedbackx) return

    if (this.$slots.default?.length === 1) {
      return this.$slots.default[0]
    }

    return createElement(
      this.tag,
      {
        attrs: {
          class: this.className
        }
      },
      this.$slots.default
    )
  }
}
