import { PluginObject } from 'vue'
import EventBus, { EventBus as eventBusClass } from '@/services/EventBus/EventBus'
import { EVENT_BUS_COMPONENT_FIELD } from '@/services/EventBus/event_bus_interfaces'

const EventBusPlugin: PluginObject<any> = {
  install: (Vue) => {
    window.EventBus = EventBus
    Vue.prototype.$eventBus = EventBus
    Vue.mixin({
      data() {
        return {
          [EVENT_BUS_COMPONENT_FIELD]: []
        }
      }
    })
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $eventBus: eventBusClass
  }
}

export default EventBusPlugin
