import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class ModeType extends Vue {
  @Prop({ default: false, type: Boolean }) settings!: boolean
  @Prop({ default: false, type: Boolean }) panel!: boolean
  @Prop({ default: false, type: Boolean }) none!: boolean
  @Prop({ default: 'div' }) tag!: string
  @Prop({ default: '' }) className!: string
  @Getter('BaseStore/getSettingsMode') isSettingsMode!: boolean

  render(createElement: any) {
    if (this.none) return
    if (!this.isSettingsMode && this.settings) return
    if (this.isSettingsMode && this.panel) return

    if (this.$slots.default?.length === 1) {
      return this.$slots.default[0]
    }

    return createElement(
      this.tag,
      {
        attrs: {
          class: this.className
        }
      },
      this.$slots.default
    )
  }
}
