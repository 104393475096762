/**
 * BaseServicePlugin - плагин, добавляющий в экземпляр Vue
 * базовые сервисы приложения:
 *
 * $baseStore - главное хранилище данных;
 * $authService - сервис авторизации.
 */

import { PluginObject } from 'vue'

import AuthService from '@/services/AuthService/index'

const AuthServicePlugin: PluginObject<any> = {
  install: function (Vue, options) {
    Vue.prototype.$authService = new AuthService(Vue, options['router'])
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $authService: AuthService
  }
}

export default AuthServicePlugin
