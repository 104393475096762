/**
 * TokenStorageService - организация работы с JWT-токеном.
 * Установка, удаление, хранение, получение.
 *
 * v1 :: Cookie-Based (см. ./TokenRepositories/TokenCookieRepository.ts)
 *
 */

import CookieRepository from '@/services/Repositories/CookieRepository'
import { TokenValue } from '@/types_and_interfaces'

interface ITokenRepos {
  account: CookieRepository | null
  study: CookieRepository | null
}

export default class TokenStorageService {
  private storage: any = null
  private api: any = null
  public readonly ACCOUNT_COOKIE_NAME = '57c43cbebe0c'
  public readonly STUDY_COOKIE_NAME = 'fa9f01351858'

  public tokens: ITokenRepos = {
    account: null,
    study: null
  }

  constructor(api: any) {
    this.api = api
    this.tokens.account = new CookieRepository(this.ACCOUNT_COOKIE_NAME)
    this.tokens.study = new CookieRepository(this.STUDY_COOKIE_NAME)
  }

  deleteToken(both = false, study = false) {
    if (both) {
      this.tokens.account?.deleteValue()
      this.tokens.study?.deleteValue()
    }
    if (study) {
      this.tokens.study?.deleteValue()
    } else {
      this.tokens.account?.deleteValue()
    }
  }
}
