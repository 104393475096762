export default class BaseStoreService {
  constructor(private $store: any) {}

  public mutate<T>(mutation: string, payload: T) {
    this.$store.commit(`BaseStore/${mutation}`, payload)
  }

  public get(getterName: string) {
    return this.$store.getters[`BaseStore/${getterName}`]
  }
}
