export const cycleAttributesConst = {
  hiddenMenuButtons: [],
  hideGeneratorButton: false,
  hideLanguageSelector: false,
  hideDynamicsCycle: false,
  excludeEmptyFilters: false,
  hideCsiProcessIndexOwner: false,
  panelLogoHeight: 45,
  customCss: '',
  customJs: '',
  startPage: '',
  customJsonData: '',
  showedFiltersInComments: [],
  showActionPlanSurvey: false,
  engagementMetricDefault: '',
  loyaltyMetricDefault: '',
  satisfactionMetricDefault: '',
  isHiddenParticipantAmount: false,
  isVisibleManagersAnswersBanner: false,
  groupWidgetSettings: null,
  hideBenchmarks: false,
  showCompanyNames: false,
  scales: []
}
