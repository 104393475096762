<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { Study } from '@/types_and_interfaces'
import { SubscribeBus } from '@/decorators'
import { STUDIES_LOADED } from '@/services/EventBus/AppEvents'

@Component({})
export default class App extends Vue {
  @Getter('BaseStore/getSelectedStudy') currentStudy!: Study
  private studyName = ''
  @Watch('$route')
  watchRoute(to: any) {
    document.title = `${this.studyName} | ${to.meta.title}` || '${this.studyName}  | Панель управления'
  }

  @SubscribeBus(STUDIES_LOADED, 'every')
  watchStudy() {
    const studyType = this.currentStudy?.type
    switch (studyType) {
      case 'happy_service':
        this.studyName = 'Happy Service'
        break
      case 'happy_job':
        this.studyName = 'Happy Job'
        break
      case 'happy_survey':
        this.studyName = 'Опроскин'
        break
      case 'feedbackx':
        this.studyName = 'FeedbackX'
        break
      default:
        this.studyName = 'Happy Service'
        break
    }
    this.watchRoute(this.$route)
  }
}
</script>

<style lang="scss">
#app {
}
</style>
