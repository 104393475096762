/**
 * API Request service
 * based on Swagger
 */
import TokenStorageService from '@/services/TokenStorageService/index'
import EventBus from '@/services/EventBus/EventBus'
import { API_READY } from '@/services/EventBus/AppEvents'
import SwaggerClient from 'swagger-client'
import { ApiResponse } from '@/types_and_interfaces'

export default class ApiService {
  private readonly settings: any = null
  public exec: any = null
  private vue: any
  private tokenService: TokenStorageService
  private $store: any
  private static specLoaded = false

  constructor(rejectUnauthorized = true, vue: any, store: any) {
    this.settings = {
      spec: {},
      rejectUnauthorized
    }
    this.vue = vue
    this.tokenService = this.vue.prototype.$authService.tokenService
    this.$store = store
  }

  public async loadSpec() {
    if (ApiService.specLoaded) return
    const spec = await this.request(`${process.env.VUE_APP_API}/openapi`, 'get', {}, null, {
      Authorization: `Bearer ${this.accountToken}`
    })
    this.settings.spec = spec.body
    this.exec = await SwaggerClient(this.settings)
    EventBus.register(API_READY)
    ApiService.specLoaded = true
  }

  private async parseResponseError(error: any, isUsingAccountToken: boolean) {
    if (error.statusCode && error.statusCode === 401) {
      if (isUsingAccountToken) {
        this.vue.prototype.$authService.doLogout()
      }
    }
  }

  async query<T = any>(
    methodName: string,
    params: any = {},
    body: any = null,
    useAccountToken = false,
    formData = false,
    locale?: string
  ): Promise<ApiResponse<T>> {
    let result
    let currentToken
    let authHeader = ''

    if (useAccountToken) {
      authHeader = this.accountToken ? `Bearer ${this.accountToken}` : ''
      currentToken = this.accountToken
    } else {
      authHeader = this.studyToken ? `Bearer ${this.studyToken}` : ''
      currentToken = this.studyToken
    }
    try {
      result = await this.exec.execute({
        operationId: methodName,
        parameters: params,
        requestBody: body,
        requestInterceptor: (req: any) => {
          req.headers['Authorization'] = authHeader
          req.headers['Locale'] = locale ?? this.$store?.getters?.['BaseStore/getCurrentLocale'] ?? ''
        }
      })
      return result
    } catch (e: any) {
      this.parseResponseError(e, useAccountToken)
      throw e
    }
  }

  public async request(
    url: string,
    method: string,
    query?: any,
    body?: any,
    headers?: any,
    useAccountToken = false
  ): Promise<any> {
    let authHeader = ''

    if (useAccountToken) {
      authHeader = this.accountToken ? `Bearer ${this.accountToken}` : ''
    } else {
      authHeader = this.studyToken ? `Bearer ${this.studyToken}` : ''
    }
    const request = {
      url,
      query,
      method,
      body,
      requestInterceptor: (req: any) => {
        req.headers['Authorization'] = authHeader
        if (body) {
          req.headers['content-type'] = 'application/json'
          return req
        }
        if (headers) {
          req.headers = {
            ...req.headers,
            ...headers
          }
        }
      }
    }
    return await SwaggerClient.http(request)
  }

  public async get(url: string, query?: any): Promise<any> {
    return await this.request(url, 'GET', query)
  }

  public async post(url: string, body?: any): Promise<any> {
    const sendBody = typeof body === 'object' ? JSON.stringify(body) : body
    return await this.request(url, 'POST', null, sendBody)
  }

  get accountToken() {
    return this.tokenService.tokens.account?.value
  }

  get studyToken() {
    return this.vue.prototype.$storeService.get('getStudyToken') ?? this.tokenService.tokens.study?.value
  }
}
