import { Notification } from 'element-ui'

export default function Success(message: string) {
  Notification.success({
    type: 'success',
    title: 'Успех',
    message,
    dangerouslyUseHTMLString: true,
    duration: 5000
  })
}
