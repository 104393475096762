import { PluginObject } from 'vue'

import AccountService from '@/services/AccountService'
import BaseStoreService from '@/services/BaseStoreService'

const AccountServicePlugin: PluginObject<any> = {
  install: function (Vue, options) {
    Vue.prototype.$account = new AccountService(Vue)
    Vue.prototype.$storeService = new BaseStoreService(options.store)
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $account: AccountService
    $storeService: BaseStoreService
  }
}

export default AccountServicePlugin
