export interface HappyIncLanguage {
  id: string
  title: string
}

export const LanguageSet: HappyIncLanguage[] = [
  {
    id: 'ru',
    title: 'Русский (ru)'
  },
  {
    id: 'en',
    title: 'Английский (en)'
  },
  {
    id: 'uz',
    title: 'Узбекский (uz)'
  },
  {
    id: 'uz_lt',
    title: 'Узбекский латиница (uz_lt)'
  },
  {
    id: 'kz',
    title: 'Казахский (kz)'
  },
  {
    id: 'kg',
    title: 'Киргизский (kg)'
  },
  {
    id: 'tg',
    title: 'Таджикский (tg)'
  },
  {
    id: 'es',
    title: 'Испанский (es)'
  },
  {
    id: 'pt',
    title: 'Португальский (pt)'
  },
  {
    id: 'hi',
    title: 'Хинди (hi)'
  },
  {
    id: 'ar',
    title: 'Арабский (ar)'
  },
  {
    id: 'ms',
    title: 'Малайский (ms)'
  },
  {
    id: 'ur',
    title: 'Урду (ur)'
  },
  {
    id: 'de',
    title: 'Немецкий (de)'
  },
  {
    id: 'sr',
    title: 'Сербский (sr)'
  },
  {
    id: 'tr',
    title: 'Турецкий (tr)'
  }
]
