export default {
  typeName: 'Кнопки',
  type: '10slide',
  metricId: { type: 'cascader', caption: 'Метрика' },
  localizedAttributes: {
    labelSelf: {
      type: 'text',
      caption: 'Название при самооценке'
    },
    hint: {
      type: 'text',
      caption: 'Подсказка по вопросу'
    },
    allowComment: {
      type: 'bool',
      caption: 'Показывать ли поле комментария',
      defaultValue: true
    },
    commentRequired: {
      type: 'bool',
      caption: 'Комментарий обязателен',
      defaultValue: true,
      dependence: 'allowComment'
    }
  },
  choices: true,
  choiceSchema: {
    mark: { type: 'number', caption: 'Балл', defaultValue: 0, nullable: true },
    attributes: {
      label: {
        type: 'text',
        caption: 'Ответ короткий'
      },
      hint: {
        type: 'text',
        caption: 'Ответ при наведении'
      },
      color: {
        type: 'text',
        caption: 'Цветовой код (HEX код - #c9a079)'
      },
      comment_required: {
        type: 'bool',
        caption: 'Обязателен ли комментарий при данном ответе',
        defaultValue: true
      }
    }
  }
}
